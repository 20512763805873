@media screen and (max-width: 499px) {
  .dots{
    color: white;
    display: flex;
    align-items: center;
  }
  
  .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;        
      }
    
      .countdown{
        text-align: center;
        color: black;
        padding: 30px;
        font-size: 25px;
        margin-top: 30%; 
        margin-bottom: 20%;
      }
    
    .days{
        background-color: #facc15;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
        
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-right: 5px;
        margin-left: 5px;
    
    }
    .sec{
        animation: 1s 0.875s infinite timer_beat;
        background-color: #facc15;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
        
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-left: 5px;
    
    }
      
    @keyframes timer_beat {
      0% {
        transform: scale(1.125); 
      }
      50% {
        transform: none; }
       }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .dots{
    color: white;
    display: flex;
    align-items: center;
  }
  
  .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        
      }
    
      .countdown{
        text-align: center;
        color: black;
        padding: 30px;
        font-size: 25px;
        margin-top: 30%; 
        margin-bottom: 20%;

      }
    
    .days{
        background-color: #facc15;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
        
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-right: 5px;
        margin-left: 5px;
    
    }
    .sec{
        animation: 1s 0.875s infinite timer_beat;
        background-color: #facc15;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
        
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-left: 5px;
    
    }
      
    @keyframes timer_beat {
      0% {
        transform: scale(1.125); 
      }
      50% {
        transform: none; }
       }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .dots{
    color: white;
    display: flex;
    align-items: center;
  }
  .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        
      }
    
      .countdown{
        text-align: center;
        color: black;
        padding: 30px;
        font-size: 35px;
        margin-top: 10%; 
        margin-bottom: 5%;
      }
    
    .days{
        background-color: #facc15;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
        
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-right: 5px;
        margin-left: 5px;
    
    }
    .sec{
        animation: 1s 0.875s infinite timer_beat;
        background-color: #facc15;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
        
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-left: 5px;
    
    }
      
    @keyframes timer_beat {
      0% {
        transform: scale(1.125); 
      }
      50% {
        transform: none; }
       }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .dots{
    color: white;
    display: flex;
    align-items: center;
  }

  .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
    
      .countdown{
        text-align: center;
        color: black;
        font-size: 60px;
        margin-top: 10%; 

      }

      
      .dots{
        color: white;
        display: flex;
        align-items: center;
      }
    
    .days{
        background-color: #facc15;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
        
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-right: 5px;
        margin-left: 5px;

    }
    .sec{
        animation: 1s 0.875s infinite timer_beat;
        background-color: #facc15;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
        
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-left: 5px;
    
    }
      
    @keyframes timer_beat {
      0% {
        transform: scale(1.125); 
      }
      50% {
        transform: none; }
       }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
    
      .countdown{
        text-align: center;
        color: black;
        font-size: 60px;     
        margin-top: 10%; 
      }
      
      .dots{
        color: white;
        display: flex;
        align-items: center;
      }
    
    .days{
        background-color: #facc15;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
        
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-right: 5px;
        margin-left: 5px;

    }
    .sec{
        animation: 1s 0.875s infinite timer_beat;
        background-color: #facc15;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
        
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-left: 5px;
    
    }
      
    @keyframes timer_beat {
      0% {
        transform: scale(1.125); 
      }
      50% {
        transform: none; }
       }
}

@media screen and (min-width: 1920px) {
  
  .dots{
    color: white;
    display: flex;
    align-items: center;
  }

  .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        
      }
    
      .countdown{
        text-align: center;
        color: black;
        font-size: 80px;
        margin-top: 10%; 

      }
    
    .days{
        background-color: #facc15;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-right: 25px;
        padding-left: 25px;
        
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-right: 5px;
        margin-left: 5px;
    
    }
    .sec{
        animation: 1s 0.875s infinite timer_beat;
        background-color: #facc15;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-right: 25px;
        padding-left: 25px;
        
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-left: 5px;
    
    }
      
    @keyframes timer_beat {
      0% {
        transform: scale(1.125); 
      }
      50% {
        transform: none; }
       }
}